import React from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import styled from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'

const NotebookRenderer = loadable(() => import('./notebook-renderer'))

import Layout from '../components/layout'

import 'react-ipynb-renderer/dist/styles/default.css'

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`

const LoadingIndicator: React.FC = () => {
  return (
    <LoadingContainer>
      <Spinner className="mx-2" size="sm" role="status" aria-hidden="true" />
      Loading
    </LoadingContainer>
  )
}

const JupyterNotebookPage = ({ data }) => {
  const { raw, parent } = data.jupyterNotebook
  const url = parent?.url
  const notebook = { ipynb: JSON.parse(raw), url }
  return (
    <Layout tocEnabled={false}>
      <NotebookRenderer notebook={notebook} fallback={<LoadingIndicator />} />
    </Layout>
  )
}

export const jupyterNotebookPageQuery = graphql`
  query JupyterNotebookQuery($id: String) {
    jupyterNotebook(id: { eq: $id }) {
      raw
      parent {
        ... on File {
          url
        }
      }
    }
  }
`

export default JupyterNotebookPage
